import { type LinkProps, Link } from '@iheartradio/web.companion';

import { useConfig } from '~app/contexts/config';

type DoNotSellMyPersonalInformationProps = LinkProps;

export const DoNotSellMyPersonalInformation = (
  props: DoNotSellMyPersonalInformationProps,
) => {
  const config = useConfig();

  return (
    <Link
      color="primary"
      data-test="ccpa-link-signupform"
      href={config.urls.optout}
      role="link"
      target="_blank"
      underline="hover"
      {...props}
    >
      Do not sell or share my personal information.
    </Link>
  );
};
